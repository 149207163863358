@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css?family=Inter:400,500,600,700,900");
:root {
  --chakra-radii-base: 0;
}

html, body {
  font-family: Inter, "Avenir Next";
}
* {
  box-sizing: border-box;
}
.select-component {
  input[aria-autocomplete=list] {
    box-shadow: none !important;
    padding-top: 20px !important;
    height: 76px !important;
  }
}
.input-placeholder {
  padding: 6px 0px 2px 24px !important;
}

div[role="tablist"] {
  // margin-top: -12px;
  z-index: 9;
  // background-color: #fff;
}
.chakra-table thead {
  z-index: 9;
}


.light,
[data-theme="light"] {
  --wui-color-modal-bg: #fff !important;
  --wui-gray-glass-005: #ddd !important;
  // --wui-color-gray-glass-005: #fff !important;
  --wui-cover: rgba(0, 0, 0, 0.2) !important;


  --morph-bg-icon: #BCBDBB;
}
wui-flex {
  background-color: #fff !important;;
}
.dark,
[data-theme="dark"] {
  --wui-color-modal-bg: #111 !important;
  --wui-gray-glass-005: #222 !important;
  // --wui-color-gray-glass-005: #111 !important;
  --wui-cover: rgba(0, 0, 0, 0.5) !important;

  --morph-bg-icon: #4D4F49;
}

.morph-logo {
  color: #21231c;
  background-image: url("/images/Logo/LogoMorphGreen-summary.svg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100% auto;
}
.morph-logo-notext {
  color: #21231c;
  background-image: url("/images/Logo/SymbolMorphGreen.svg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100% auto;
}

html, body, #__next {
  height: 100%;
  min-height: 100%;
}
